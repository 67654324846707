import BackNavigation from "../../components/molecules/BackNavigation";
import LoadingButton from "../../components/molecules/LoadingButton";
import InputText from "../../components/organisms/InputText";
import Navigation from "../../components/organisms/Navigation";
import PopupOverlayDelete from "../../components/templates/PopupOverlayDelete";
import ScreenContainer from "../../components/templates/ScreenContainer";
import wallet from "../../assets/images/icon/wallet.svg";
import { useMutation } from "@tanstack/react-query";
import { createKas, updateKas } from "../../services/kas.services";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toastError, toastSuccess, toastWarning } from "../../components/atoms/Toast";

const ADMIN = "admin";
const InputDompet = (props) => {
  const { isEdit = false } = props;
  const { communityId } = useParams()
  const { state } = useLocation();
  if (isEdit && (state === null || state === undefined)) {
    // no data to edit, redirect to kas page
    window.location.replace(`/app/kas/${communityId}`);
    return
  }
  // prevent non admin access by checking userRole form state
  const { userRole, kas } = state;
  if (userRole !== ADMIN) {
    // redirect to kas page for non admin
    window.location.replace(`/app/kas/${communityId}`);
    return
  }
  const [kasName, setKasName] = useState(kas?.name);
  const [kasAmmount, setKasAmmount] = useState(kas?.price_per_member);
  const [isPrimary, setIsPrimary] = useState(kas?.is_primary);
  const [allertNoNewPrimary, setAllertNoNewPrimary] = useState(true);
  const {mutate: createKasRequest, isPending: isCreatingKas} = useMutation({
    mutationFn: isEdit? updateKas : createKas,
    mutationKey: ["kas", communityId, kas?._id],
    onSuccess: (resp) => {
      if (resp.status === 201 || resp.status === 200) {
        toastSuccess(resp.message)
        window.location.href = `/app/kas/${communityId}`;
      } else {
        toastWarning(resp.message)
      }
    },
    onError: (error) => {
      const serverErrMessage = error.response?.data?.message
      if (serverErrMessage) {
        toastError(serverErrMessage)
      } else {
        toastError(error.message)
      }
    },
  });
  const handleSubmiteKas = () => {
    const req = {
      communityId,
      kasId : kas?._id,
      data : {
        name: kasName,
        price_per_member: Number(kasAmmount),
        is_primary: isPrimary,
      }
    }
    if (kasName && kasAmmount) {
      createKasRequest(req);
    }
  };
  return (
    <ScreenContainer>
      <div className="fixed top-0 w-full h-14 z-10 bg-white px-4 pt-4 md:w-[480px]-auto pb-10">
        <BackNavigation text="Manajemen Kas" to={`/app/kas/${communityId}`} />
      </div>
      <div className="h-14"></div>
      <div className="px-4">
        <div>
          <h1 className="text-2xl font-bold mb-4">
            {isEdit ? "Edit Dompet Kas" : "Dompet Kas Baru"}
          </h1>
          <div className="my-1">
            <InputText
              name="walet-name"
              placeholder="Masukan nama dompet kas"
              text="Nama Dompet Kas"
              value={kasName}
              setValue={setKasName}
            />
          </div>
          <div className="my-1">
            <InputText
              name="walet-ammount"
              placeholder="Masukkan biaya per member"
              text="Biaya Kas (per member)"
              isCurrency
              value={kasAmmount}
              setValue={setKasAmmount}
            />
          </div>
          <div>
            {/* the checkbox toggle taken from https://medium.com/front-end-weekly/build-a-css-only-toggle-switch-using-tailwindcss-d2739882934 */}
            <label className="relative flex justify-between items-center group py-4 text-sm font-semibold">
              Jadikan dompet kas utama
              <input
                type="checkbox"
                className="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md"
                checked={isPrimary}
                onChange={(e) => setIsPrimary(e.target.checked)}
              />
              <span className="w-10 h-6 flex items-center flex-shrink-0 ml-4 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-primary-500 after:w-5 after:h-5 after:bg-black-800 after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3.5 peer-checked:after:bg-white group-hover:after:translate-x-1"></span>
            </label>
          </div>
          <LoadingButton
            warna="hijau"
            onClick={handleSubmiteKas}
            isPending={isCreatingKas}
            y={3}
          >
            {isEdit ? "Simpan Perubahan" : "Buat Dompet Kas"}
          </LoadingButton>
        </div>
      </div>
      <PopupOverlayDelete hidden={!allertNoNewPrimary} setHidden={setAllertNoNewPrimary}>
        <div className="flex items-center justify-center h-full flex-col">
          <div className="m-2">
            <img src={wallet} alt="walet" width={80} />
          </div>
          <div className="text-wrap w-1/2 items-center">
            <p className="text-center text-lg font-bold ">
              Dompet utama hanya bisa dibuat sekali
            </p>
          </div>
        </div>
      </PopupOverlayDelete>
      <div className="px-4 pt-4 pb-10"></div>
      <Navigation />
    </ScreenContainer>
  );
};

export default InputDompet;
