import React from 'react'
import { NavLink } from 'react-router-dom'

export default function IconText(props) {
  const {text, children, to } = props
  return (
    <NavLink end to={to} className={({ isActive }) => (isActive ? 'text-green-700 space-y-1' : 'text-black-400 space-y-1')}>
      <div className='flex justify-center'>
        {children}
      </div>
      <div className='text-center text-xs'>{text}</div>
    </NavLink>
  )
}
