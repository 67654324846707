import React from 'react'
import CloseIcon from '../atoms/CloseIcon'

export default function PopupOverlay(props) {
  const {hidden, setHidden, title, children} = props

  const setBody = () => {
    document.querySelector('body').classList.toggle('fixBody');
  }
  return (
    <div>
      <div className={`absolute ${!hidden ? '-z-10' : 'z-30'} w-full bottom-0 `}>
            {/* overlay */}
            <div className={`fixed inset-0 w-full h-full duration-600 ${hidden ? 'opacity-100' : 'opacity-0 invisible'}  ease-in-out`}>
              <div className="flex justify-center min-h-screen">
                <div className="w-full md:w-[480px] bg-black/20">
                </div>
              </div>
            </div>
            {/* end overlay */}
            <div className={`absolute flex bg-white w-full h-96 lg:h-auto overflow-auto  ${!hidden ? '-z-10' : 'z-30'} transform  ${hidden ? 'bottom-0 transition-all duration-600 opacity-100' : 'bottom-10 opacity-0 invisible'} pb-0 md:pb-16  `}>
              <div className="w-full p-4">
                <div className='flex justify-end'>
                  <button type='button' onClick={() => {setHidden(!hidden); setBody()}}>
                    <CloseIcon/>
                  </button>
                </div>
                <div>
                  <div className='font-semibold text-xl pb-4'>
                    {title}
                  </div>

                  <div className="space-y-3">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
