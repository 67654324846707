import React, { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useToken from '../hooks/useToken';

function ScreenContainer(props) {
  const {children} = props
  const location = useLocation()
  const {token} = useToken() 
  if(!token || token === null){
    if(location.pathname !== '/login'){
      console.log(location);
      return <Navigate to="/login" state={{from: window.location.href}} replace /> 
    }
  }
  return (
    <div className='font-inter bg-gray-50'>
      <div className="flex justify-center min-h-screen">
        <div className="w-full md:w-[480px] bg-white relative">
            {children}
        </div>
      </div>
    </div>
  )
}

export default ScreenContainer