import { jwtDecode } from 'jwt-decode'
import {useEffect, useState} from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

const useToken = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const expiredCheck = (token) => {
      const decoded = jwtDecode(token);
      const isExpired = decoded.exp * 1000 < Date.now()
      if(isExpired){
        localStorage.removeItem('token')
        if(location.pathname !== '/login'){
          return <Navigate to="/login" state={{from: window.location.href}} replace /> 
        }
      }
    }

    const getToken = () =>{
        const tokenString = localStorage.getItem('token')
        const userToken = JSON.parse(tokenString)
        return userToken
    } 

    const [token, setToken] = useState(getToken())

    useEffect(() => {
      if(token){
        expiredCheck(token)
      }
    }, [token])
    

    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken))
        setToken(userToken)
      }

      return {
        setToken: saveToken,
        token
      }
  
}

export default useToken