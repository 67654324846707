import { useEffect, useState } from "react";
import BackNavigation from "../../components/molecules/BackNavigation";
import ScreenContainer from "../../components/templates/ScreenContainer";
import IconPeoples from "../../components/atoms/IconPeoples";
import Button from "../../components/atoms/Button";
import Navigation from "../../components/organisms/Navigation";
import IconCheck from "../../components/atoms/IconCheck";
import kasWallet from "../../assets/images/kasWallet.svg";
import totalKasTopRight from "../../assets/images/memphis/total_kas_top_right.svg";
import IconUser from "../../components/atoms/IconUser";
import IconCreditCard from "../../components/atoms/IconCreditCard";
import IconPlus from "../../components/atoms/IconPlus";
import {
  optionPaymentDigital,
  optionPaymentBank,
} from "../../components/Utils/optionPayment";
import IconMenu from "../../components/atoms/IconMenu";
import PopupOverlay from "../../components/templates/PopupOverlay";
import IconRight from "../../components/atoms/IconRight";
import IconPencil from "../../components/atoms/IconPencil";
import IconTrash from "../../components/atoms/IconTrash";
import PopupOverlayDelete from "../../components/templates/PopupOverlayDelete";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteKasById,
  getKasById,
  getMembersKasPaymentStatusByKasId,
  payOutKas,
  postKasTransaction,
  updateKasPrimaryStatus,
} from "../../services/kas.services";
import { useNavigate, useParams } from "react-router-dom";
import PulseLoadingPost from "../../components/atoms/PulseLoadingPost";
import { rupiah } from "../../components/Utils/utils";
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarning,
} from "../../components/atoms/Toast";
import IconLoading from "../../components/atoms/IconLoading";
import { getMemberByCommunityIDService } from "../../services/member.services";
import LoadingButton from "../../components/molecules/LoadingButton";
import { getUserChannels } from "../../services/channel.service";
import InputText from "../../components/organisms/InputText";

const STATUS_BAYAR = "Status Bayar";
const PENARIKAN = "Penarikan";
const NOT_PAID = "not paid";

const MINIMUM_PRIMARY_KAS_PAYOUT = 50_000

const DetailKasAdminPOV = (props) => {
  const { user, userRole } = props;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { communityId, id } = useParams();
  if (id === undefined || id === null) {
    window.location.href = `/app/kas/${communityId}`;
  }
  const {
    data: dataKas,
    error: isErrorKas,
    isPending: isPendingKas,
    refetch: refetchKas,
  } = useQuery({ queryFn: () => getKasById(id), queryKey: ["kasById", id] });
  const { data: dataMembersKasStatus, isPending: isPendingMembersKasStatus } =
    useQuery({
      queryKey: ["kas-members-status", id],
      queryFn: () => getMembersKasPaymentStatusByKasId(id),
    });
  const [activeMenu, setActiveMenu] = useState(STATUS_BAYAR);
  const [settingOpen, setSettingOpen] = useState(false);
  const [popupDeleteOpen, setPopupDeleteOpen] = useState(false);
  const [linkedDigitalPayments, setLinkedDigitalPayments] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [minimumPrimaryKasPayOutAllert, setMinimumPrimaryKasPayOutAllert] = useState(false)
  const [zeroAmountKasDeletionAllert, setZeroAmountKasDeletionAllert] = useState(false)
  const [payOutInputs, setPayOutInputs] = useState({
    amount: null,
    holderName: null,
    accountNumber: null,
  });
  const { mutate: deleteKas, isPending: isPendingDeleteKas } = useMutation({
    mutationFn: (id) => deleteKasById(id),
    onSuccess: (resp) => {
      if (resp.status === 200) {
        setPopupDeleteOpen(false);
        setSettingOpen(false);
        toastSuccess(resp.message);
        // refresh page
        window.location.href = `/app/kas/${communityId}`;
      } else {
        toastWarning(resp.message);
      }
    },
    onError: (error) => {
      const serverErrMessage = error.response?.data?.message
      if (serverErrMessage) {
        toastError(serverErrMessage)
      } else {
        toastError(error.message)
      }
    },
  });
  const {
    mutate: updatePrimaryStatus,
    isPending: isPendingUpdatePrimaryStatus,
  } = useMutation({
    mutationFn: updateKasPrimaryStatus,
    onSuccess: (resp) => {
      if (resp.status === 200) {
        // refresh kas data
        // refetchKas();
        // invalidate feth kas query
        queryClient.invalidateQueries({queryKey: ["kasById", id]})
        toastSuccess(resp.message);
      } else {
        toastWarning(resp.message);
      }
    },
    onError: (error) => {
      const serverErrMessage = error.response?.data?.message
      if (serverErrMessage) {
        toastError(serverErrMessage)
      } else {
        toastError(error.message)
      }
    },
  });
  const handlePopupDeleteOpen = () => {
    if (parseInt(dataKas?.kas?.amount) > 0 ) {
      setZeroAmountKasDeletionAllert(true)
    } else {
      setPopupDeleteOpen(true);
    }
    setSettingOpen(false);
  };
  const handlePopupDeleteClose = () => {
    setPopupDeleteOpen(false);
    setSettingOpen(true);
  };
  const perfomDeleteKas = (kasId) => {
    // TODO: delete kas
    deleteKas(kasId);
  };
  const handleSetIsPrimary = (kasId, isPrimary) => {
    // const isHasPrimary = dataKas.kas.some((kas) => kas.is_primary);
    // const primaryKas = dataKas.kas.find((kas) => kas.is_primary);
    // const isHasPrimary = false;
    // const primaryKas = { _id: kasId };
    // if (isPrimary && isHasPrimary && primaryKas._id !== kasId) {
    //   // setAllertNoNewPrimary(true);
    //   // setSettingOpen(false);
    // } else {
    //   // TODO: update kas is_primary
    //   updatePrimaryStatus({
    //     kasId,
    //     data: {
    //       is_primary: isPrimary,
    //     },
    //   });
    // }
    // directly update primary status
    updatePrimaryStatus({
      kasId,
      data: {
        is_primary: isPrimary,
      },
    });
  };
  const {data: userChannels, isLoading: isLoadingUserChannels} = useQuery({
    queryKey: ["channels", user._id],
    queryFn: getUserChannels,
    enabled: false // next feature
  });
  // NEXT FEATURE
  // useEffect(() => {
  //   if (userChannels) {
  //     const linkedDigitalPayments = optionPaymentDigital.filter((payment) => (
  //       userChannels?.channel.some((channel) => channel.channel_code === payment.id)
  //     ));
  //     setLinkedDigitalPayments(linkedDigitalPayments);
  //   }
  // }, [userChannels])
  const { mutate: payKas, isPending: isPendingPayKas } = useMutation({
    mutationFn: postKasTransaction,
    mutationKey: ["pay-kas", id],
    onSuccess: (resp) => {
      if (resp.status === 201) {
        toastSuccess(resp.message);
        window.location.replace(resp.data.invoice_url);
      } else {
        toastWarning(resp.message);
      }
    },
    onError: (error) => {
      const serverErrMessage = error.response?.data?.message
      if (serverErrMessage) {
        toastError(serverErrMessage)
      } else {
        toastError(error.message)
      }
    },
  });
  const performPayKas = () => {
    if (dataKas?.kas?.is_primary || !isMemberPaid()) {
      payKas({
        item_id: id,
      });
    } else {
      toastInfo("You have already paid for this kas");
    }
  };
  const isMemberPaid = () => {
    if (!isPendingMembersKasStatus) {
      const status = dataMembersKasStatus?.data.some(
        (member) =>
          user._id === member.user._id &&
          member.payment_status !== NOT_PAID
      );
      return status
    }
    return false;
  };
  useEffect(() => {
    if (dataKas?.kas) {
      if (dataKas?.kas?.is_primary) {
        setActiveMenu(PENARIKAN);
      } else {
        setPayOutInputs({
          ...payOutInputs,
          amount: parseInt(dataKas?.kas?.amount),
        })
      }
    }
  }, [dataKas]);

  const {mutate: withdrawlKas, isPending: isPendingWithdrawlKas} = useMutation({
    mutationFn: payOutKas,
    mutationKey: ["pay-out-kas", id],
    onSuccess: (resp) => {
      if (resp.status === 200) {
        toastSuccess(resp.message)
        navigate(`/app/kas/${communityId}/tarik/sukses`, {state: { message: "Penarikan Kas Berhasil" }})
      } else {
        toastWarning(resp.message)
      }
    },
    onError: (error) => {
      const serverErrMessage = error.response?.data?.message
      if (serverErrMessage) {
        toastError(serverErrMessage)
      } else {
        toastError(error.message)
      }
    },
  })
  const performWithdrawlKas = () => {
    if (payOutInputs.amount !== null && payOutInputs.holderName && payOutInputs.accountNumber) {
      if (payOutInputs.amount > parseInt(dataKas?.kas?.amount)) {
        toastError("Jumlah penarikan melebihi jumlah kas")
      } else if (dataKas?.kas?.is_primary && payOutInputs.amount < MINIMUM_PRIMARY_KAS_PAYOUT ) {
        setMinimumPrimaryKasPayOutAllert(true)
      } else {
        withdrawlKas({
          kasId: id,
          data: {
            channel_code: selectedPaymentMethod.id,
            account_holder_name: payOutInputs.holderName,
            account_number: payOutInputs.accountNumber,
            amount: payOutInputs.amount,
          }
        })
      }
    } else {
      toastWarning("Silahkan lengkapi data penarikan")
    }
  }

  return (
    <ScreenContainer>
      <div className="h-[90vh] overflow-y-auto">
        <div className="fixed flex justify-between items-center top-0 w-full h-14 z-10 bg-white px-4 py-4 md:w-[480px]">
          <BackNavigation text="Detail Komunitas" to={`/app/kas/${communityId}`} />
          <div
            className="flex items-center"
            onClick={() => setSettingOpen(true)}
          >
            <IconMenu strokeWidth={2} />
          </div>
        </div>
        <div className="h-14"></div>
        {/* card kas data */}
        <div className="relative m-4 p-4 shadow-md rounded-lg overflow-hidden">
          <div className="absolute top-0 right-0">
            <img src={totalKasTopRight} alt="kas detail memphis top" />
          </div>
          <div className="">
            <div className="bg-white rounded-lg p-4">
              {/* card total content */}
              <div className="flex justify-between">
                {isPendingKas ? (
                  <div className="w-1/2">
                    <PulseLoadingPost size={4} />
                    <PulseLoadingPost size={10} mt={1} />
                  </div>
                ) : (
                  <div>
                    <h1 className="text-md font-semibold">
                      {dataKas?.kas?.name}
                    </h1>
                    <p className="text-3xl font-bold">
                      {rupiah(parseInt(dataKas?.kas?.amount))}
                    </p>
                    <p className="text-sm font-bold pt-1">
                      {rupiah(dataKas?.kas?.price_per_member)} / member
                    </p>
                  </div>
                )}
                <div>
                  <img src={kasWallet} alt="kas wallet images"   />
                </div>
              </div>
            </div>
            {/* add buttons to switch menu if the kas is non-primary */}
            {!(dataKas?.kas?.is_primary) && (
              <>
                <hr />
                <div>
                  {/* buttons */}
                  <div className="grid gap-3 grid-cols-2 py-4">
                    <Button
                      warna={activeMenu === STATUS_BAYAR ? "primary" : "putih"}
                      y={2}
                      onClick={() => {
                        setActiveMenu(STATUS_BAYAR);
                      }}
                    >
                      <div className="flex justify-center items-center">
                        <span className="mr-1">
                          <IconUser strokewidth={2} />
                        </span>
                        {STATUS_BAYAR}
                      </div>
                    </Button>
                    <Button
                      warna={activeMenu === PENARIKAN ? "primary" : "putih"}
                      y={2}
                      onClick={() => {
                        setActiveMenu(PENARIKAN);
                      }}
                    >
                      <div className="flex justify-center items-center">
                        <span className="mr-1">
                          <IconCreditCard strokewidth={2} />
                        </span>
                        {PENARIKAN}
                      </div>
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {/* pay kas button */} 
        <div className="pt-1">
        {isPendingMembersKasStatus ? (
            <div className="m-2 mx-4">
              <PulseLoadingPost size={16} />
            </div>
          ) : !(dataKas?.kas?.is_primary) && isMemberPaid() ? (
            <div className="m-2 mx-4 rounded-xl bg-gray-400 text-white overflow-hidden cursor-not-allowed">
              <div className="flex justify-center items-center p-4">
                <p>Kamu sudah bayar</p>
              </div>
            </div>
          ) : (
            <div className="m-2 mx-4 rounded-xl overflow-hidden">
              <LoadingButton
                isPending={isPendingPayKas}
                onClick={() => performPayKas()}
              >
                <div className="h-7 flex justify-center items-center">
                  <p>Bayar Kas</p>
                </div>
              </LoadingButton>
            </div>
          )}
        </div>
        {/* card kas data end */}
        {/* card payment method */}
        {activeMenu === STATUS_BAYAR ? (
          <div>
            {/* Card list status anggota */}
            <div className="px-4 pt-4 pb-10">
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-semibold">Status Anggota</h1>
              </div>
              {isPendingMembersKasStatus ? (
                <>
                  <PulseLoadingPost size={16} />
                  <PulseLoadingPost size={16} mt={3} />
                  <PulseLoadingPost size={16} mt={3} />
                  <PulseLoadingPost size={16} mt={3} />
                </>
              ) : (
                <>
                  {dataMembersKasStatus?.data.map((member, i) => {
                    const isPaid = member.payment_status !== NOT_PAID;
                    return (
                      <div
                        key={i}
                        className={`flex my-3 ${
                          isPaid ? "bg-white" : "bg-gray-100"
                        } justify-between items-center shadow-sm rounded-md p-2`}
                      >
                        <div className="flex justify-start items-center ">
                          <div>
                            {/* profile picture */}
                            <div className="w-14 h-14 bg-gray-200 rounded-full flex items-center justify-center overflow-hidden">
                              <img
                                src={member.user.user_photo.image_url}
                                alt={member.user.nama_lengkap}
                                 
                              />
                            </div>
                          </div>
                          <div className="flex justify-center items-center h-full flex-col mx-3">
                            {/* profil name */}
                            <div className="text-lg">
                              <p>{member.user.nama_lengkap}</p>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* bill status */}
                          <div className="flex justify-center items-center">
                            {isPaid ? (
                              <div className="text-xs flex bg-gray-100 rounded-md p-2">
                                <div className="bg-primary-500 text-xs font-light rounded-full mx-1">
                                  <IconCheck />
                                </div>
                                <span>Sudah Bayar</span>
                              </div>
                            ) : (
                              <div className="text-xs font-semibold ">
                                <span>Belum Bayar</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        ) : selectedPaymentMethod ? (
          <div className="m-5">
            <div onClick={() => setSelectedPaymentMethod(null)} className="flex justify-start items-center cursor-pointer py-2 gap-2">
              <div className="rotate-180 scale-90">
                <IconRight />
              </div>
              <p className="text-md font-semibold hover:underline" >Pilih Metode Penarikan</p>
            </div>
            <div className="py-1" >
              {dataKas?.kas.is_primary ? (
                <InputText
                  name="withdrawl-amount"
                  text="Masukan Nominal Penarikan"
                  placeholder="Rp 150.000"
                  type="number"
                  value={payOutInputs.amount}
                  setValue={(value) =>
                    setPayOutInputs({...payOutInputs, amount: value })
                  }
                  isCurrency
                />
              ) : (
                <div  className="cursor-not-allowed">
                  <p className="font-medium py-2" >Masukan Nominal Penarikan</p>
                  <div className="border border-gray-500 p-3 py-4 rounded-xl ">
                    <p>
                      <p>{rupiah(parseInt(dataKas?.kas?.amount))}</p>
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="py-1" >
              <InputText
                name="acoount-holder"
                text={`Masukan Nama Pemilik ${optionPaymentDigital.some((item) => item.id === selectedPaymentMethod.id) ? "e-Walet" : "Rekening Bank"}`}
                placeholder="Andi Santoso"
                value={payOutInputs.holderName}
                setValue={(value) =>
                  setPayOutInputs({...payOutInputs, holderName: value })
                }
                type="text"
              />
            </div>
            <div className="py-1" >
              <InputText
                name="account-number"
                text={`Masukan ${selectedPaymentMethod.identifierName}`}
                placeholder="0123456789"
                value={payOutInputs.accountNumber}
                setValue={(value) =>
                  setPayOutInputs({...payOutInputs, accountNumber: value })
                }
                type="tel"
              />
            </div>
            <div className="py-4" >
              <LoadingButton isPending={isPendingWithdrawlKas} onClick={() => performWithdrawlKas()}>
                  Tarik Dana
              </LoadingButton>
            </div>
        </div>
        ) : (
          <div>
            <div className="px-4 pt-4 pb-10">
              {/* Withdrawl Method list */}
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-semibold">Metode Penarikan</h1>
              </div>
              {isLoadingUserChannels ? (
                <div>
                  <PulseLoadingPost size={16} />
                  <PulseLoadingPost size={16} mt={3} />
                  <PulseLoadingPost size={16} mt={3} />
                  <PulseLoadingPost size={16} mt={3} />
                </div>
              ) : (
                <div>
                  {/* NEXT FEATURE */}
                  {/* Withdrawl Method card list */}
                  {/* Linked E-Waled card */}
                  {linkedDigitalPayments.map((item, index) => (
                    <div
                      key={index}
                      className="flex my-3 bg-white justify-center items-center border-2 rounded-xl"
                      onClick={() => setSelectedPaymentMethod(item)}
                    >
                      <div className="flex m-2 items-center justify-between w-full">
                        <div className="flex justify-start items-center py-1">
                          <div className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full">
                            {/* logo payment method */}
                            <img
                              src={item.image}
                              alt={item.name}
                              width={"35px"}
                               
                            />
                          </div>
                          <div className="flex justify-between items-start h-full flex-col mx-3">
                            <div className="text-xl font-semibold">
                              {/* payment method name */}
                              {item.name}
                            </div>
                            <div className="text-xs">
                              {/* payment method description */}
                              {`Akun anda sudah terhubung dengan ${item.name}`}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="flex justify-center items-center">
                            <div className="text-lg flex border border-black-500 justify-center items-center rounded-full h-5 w-5"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* E-walet card */}
                  {optionPaymentDigital
                    .filter((item) => {
                      for (let i = 0; i < linkedDigitalPayments.length; i++) {
                        if (item.name === linkedDigitalPayments[i].name) {
                          return false;
                        }
                      }
                      return true;
                    })
                    .map((item, index) => (
                      <div
                        key={index}
                        className="flex my-3 bg-white justify-center items-center border-2 rounded-xl"
                        onClick={() => setSelectedPaymentMethod(item)}
                      >
                        <div className="flex m-2 items-center justify-between w-full">
                          <div className="flex justify-start items-center py-1">
                            <div className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full">
                              {/* logo payment method */}
                              <img
                                src={item.image}
                                alt={item.name}
                                width={"35px"}
                                 
                              />
                            </div>
                            <div className="flex justify-between items-start h-full flex-col mx-3">
                              <div className="text-xl font-semibold">
                                {/* payment method name */}
                                {item.name}
                              </div>
                              <div className="text-xs">
                                {/* payment method description */}
                                {`Hubungkan akun ${item.name} kamu`}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="flex justify-center items-center">
                              <div className="text-lg flex bg-primary-500 justify-center items-center rounded-full h-5 w-5">
                                <span className="">+</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {/* End of E-walet card  */}
                  {/* Bank VA card */}
                  {optionPaymentBank.map((item, index) => (
                    <div
                      key={index}
                      className="flex my-3 bg-white justify-center items-center border-2 rounded-xl"
                      onClick={() => setSelectedPaymentMethod(item)}
                    >
                      <div className="flex m-2 items-center justify-between w-full">
                        <div className="flex justify-start items-center py-1">
                          <div className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full">
                            {/* logo payment method */}
                            <img
                              src={item.image}
                              alt={item.name}
                              width={"35px"}
                               
                            />
                          </div>
                          <div className="flex justify-between items-start h-full flex-col mx-3">
                            <div className="text-xl font-semibold">
                              {/* payment method name */}
                              {item.name}
                            </div>
                            <div className="text-xs">
                              {/* payment method description */}
                              {`Hubungkan kartu debit ${item.name}`}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="flex justify-center items-center">
                            <div className="text-lg flex bg-primary-500 justify-center items-center rounded-full h-5 w-5">
                              <span className="">+</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) }
            </div>
          </div>
        )}
        {/* popup setting */}
        <PopupOverlay
          hidden={settingOpen}
          setHidden={setSettingOpen}
          title="Setting"
        >
          <div className={isPendingUpdatePrimaryStatus ? "opacity-50" : ""}>
            <label className="relative flex justify-between items-center group py-4 text-sm font-semibold">
              Jadikan Dompet Kas Utama
              <input
                type="checkbox"
                className="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md"
                checked={dataKas?.kas?.is_primary}
                disabled={isPendingUpdatePrimaryStatus}
                onChange={(e) =>
                  handleSetIsPrimary(dataKas?.kas?._id, e.target.checked)
                }
              />
              <span className="w-10 h-6 flex items-center flex-shrink-0 ml-4 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-primary-500 after:w-5 after:h-5 after:bg-black-800 after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3.5 peer-checked:after:bg-white group-hover:after:translate-x-1"></span>
            </label>
          </div>
          <div
            className="flex justify-between items-center p-4 border border-gray-300 rounded-lg"
            onClick={() =>
              navigate(`/app/kas/${communityId}/edit`, { state: {userRole, kas: settingData} })
            }
          >
            <div className="flex justify-start">
              <IconPencil />
              <span className="ml-2 text-sm font-bold">Edit Dompet kas</span>
            </div>
            <div>
              <IconRight />
            </div>
          </div>
          <div className="mt-4">
            <button
              className="bg-[#FEE1D2] rounded-md flex justify-center items-center w-full p-3 text-md font-semibold text-[#F31F1F] hover:bg-primary-500"
              onClick={() => handlePopupDeleteOpen()}
            >
              <IconTrash />
              <span className="ml-2">Hapus Dompet kas</span>
            </button>
          </div>
        </PopupOverlay>
        {/* Popup delete confirmation */}
        <PopupOverlayDelete
          hidden={popupDeleteOpen}
          setHidden={setPopupDeleteOpen}
          setHiddenMenu={setSettingOpen}
        >
          <div className="flex justify-center items-center h-full flex-col">
            <div className="h-10 w-10 bg-primary-300 rounded-full flex justify-center items-center">
              <IconTrash />
            </div>
            <p className="text-md font-bold text-center mt-1">
              Apakah Kamu Yakin?
            </p>
            <div className="flex justify-center items-center mt-1">
            <span>
              <p className="inline text-sm font-light me-1">Ingin menghapus dompet</p>
              <p className="inline">{`"${dataKas?.kas?.name}"`}</p>
            </span>
            </div>
            <div className="grid grid-cols-2 gap-2 mt-4 w-full">
              <button
                type="button"
                className="border-black-500 border hover:bg-primary-200 hover:border-transparent rounded-md w-full h-9 text-md font-semibold text-gray-800"
                onClick={() => handlePopupDeleteClose()}
              >
                {" "}
                Batal{" "}
              </button>
              <button
                type="button"
                className={`${
                  isPendingDeleteKas ? "bg-black-200" : "bg-[#FEE1D2]"
                } rounded-md w-full h-9 text-md font-semibold text-[#F31F1F] hover:bg-primary-500`}
                onClick={() => perfomDeleteKas(dataKas?.kas?._id)}
                disabled={isPendingDeleteKas}
              >
                <div className="flex justify-center space-x-2 items-center">
                  {isPendingDeleteKas ? <IconLoading /> : ""}
                  <span>{isPendingDeleteKas ? "Loading..." : "Hapus"}</span>
                </div>
              </button>
            </div>
          </div>
        </PopupOverlayDelete>
        {/* Popup aller deletion if the ammount of kas that will be deleted is 0 */}
        <PopupOverlayDelete hidden={zeroAmountKasDeletionAllert} setHidden={setZeroAmountKasDeletionAllert} setHiddenMenu={setSettingOpen}>
          <div className="flex justify-center items-center h-full flex-col">
            <div className="h-16 w-16 bg-primary-300 rounded-full flex justify-center items-center">
              <img src={kasWallet} alt="kas walet"   width={"80px"} />
            </div>
            <p className="text-md font-bold text-center mt-1">
              Pastikan saldo di dompet kas Rp 0 sebelum menghapusnya
            </p>
          </div>
        </PopupOverlayDelete>
        {/* Popup allert payout pirmary kas if ammount les than Rp 50.000 */}
        <PopupOverlayDelete hidden={minimumPrimaryKasPayOutAllert} setHidden={setMinimumPrimaryKasPayOutAllert} setHiddenMenu={() => {}}>
          <div className="flex justify-center items-center h-full flex-col">
            <div className="h-16 w-16 bg-primary-300 rounded-full flex justify-center items-center">
              <img src={kasWallet} alt="kas walet"   width={"80px"} />
            </div>
            <p className="text-md font-bold text-center mt-1">
              Minimal penarikan {rupiah(MINIMUM_PRIMARY_KAS_PAYOUT)} untuk kas dompet utama
            </p>
          </div>
        </PopupOverlayDelete>
        <div className="px-4 pt-4 pb-10"></div>
      </div>
      <Navigation />
    </ScreenContainer>
  );
};

export default DetailKasAdminPOV;
