import BackNavigation from "../../components/molecules/BackNavigation";
import ScreenContainer from "../../components/templates/ScreenContainer";
import Button from "../../components/atoms/Button";
import Navigation from "../../components/organisms/Navigation";
import IconCheck from "../../components/atoms/IconCheck";
import kasWallet from "../../assets/images/kasWallet.svg";
import memberDetailKasUtama from "../../assets/images/member_detail_kas_utama.svg";
import totalKasTopRight from "../../assets/images/memphis/total_kas_top_right.svg";
import IconMenu from "../../components/atoms/IconMenu";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getKasById,
  getMembersKasPaymentStatusByKasId,
  postKasTransaction,
} from "../../services/kas.services";
import { useNavigate, useParams } from "react-router-dom";
import PulseLoadingPost from "../../components/atoms/PulseLoadingPost";
import { rupiah } from "../../components/Utils/utils";
import { getProfile } from "../../services/profile.services";
import LoadingButton from "../../components/molecules/LoadingButton";
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarning,
} from "../../components/atoms/Toast";

const NOT_PAID = "not paid";

const DetailKasMemberPOV = (props) => {
  const { user, userRole } = props;
  const navigate = useNavigate();
  const { communityId, id } = useParams();
  if (id === undefined || id === null) {
    window.location.href = `/app/kas/${communityId}`;
  }
  const {
    data: dataKas,
    error: isErrorKas,
    isPending: isPendingKas,
    refetch: refetchKas,
  } = useQuery({ queryFn: () => getKasById(id), queryKey: ["kasById", id] });
  const { data: dataMembersKasStatus, isPending: isPendingMembersKasStatus } =
    useQuery({
      queryKey: ["kas-members-status", id],
      queryFn: () => getMembersKasPaymentStatusByKasId(id),
    });
  const isMemberPaid = () => {
    if (!isPendingMembersKasStatus) {
      const status = dataMembersKasStatus?.data.some(
        (member) =>
          user._id === member.user._id &&
          member.payment_status !== NOT_PAID
      );
      return status
    }
    return false;
  };
  const { mutate: payKas, isPending: isPendingPayKas } = useMutation({
    mutationFn: postKasTransaction,
    mutationKey: ["pay-kas", id],
    onSuccess: (resp) => {
      if (resp.status === 201) {
        toastSuccess(resp.message);
        window.location.replace(resp.data.invoice_url);
      } else {
        toastWarning(resp.message);
      }
    },
    onError: (error) => {
      const serverErrMessage = error.response?.data?.message
      if (serverErrMessage) {
        toastError(serverErrMessage)
      } else {
        toastError(error.message)
      }
    },
  });
  const performPayKas = () => {
    if (dataKas?.kas?.is_primary || !isMemberPaid()) {
      payKas({
        item_id: id,
      });
    } else {
      toastInfo("You have already paid for this kas");
    }
  };
  return (
    <ScreenContainer>
      <div className="h-[90vh] overflow-y-auto">
        <div className="fixed flex justify-between items-center top-0 w-full h-14 z-10 bg-white px-4 py-4 md:w-[480px]">
          <BackNavigation text="Detail Komunitas" to={`/app/kas/${communityId}`} />
        </div>
        <div className="h-14"></div>
        {/* card kas data */}
        <div className="relative m-4 p-4 shadow-md rounded-lg overflow-hidden">
          <div className="absolute top-0 right-0">
            <img src={totalKasTopRight} alt="kas detail memphis top right" />
          </div>
          <div className="">
            <div className="bg-white rounded-lg p-4">
              {/* card total content */}
              <div className="flex justify-between items-center">
                {isPendingKas ? (
                  <div className="w-1/2">
                    <PulseLoadingPost size={4} />
                    <PulseLoadingPost size={10} mt={1} />
                  </div>
                ) : (
                  <div>
                    <h1 className="text-md font-semibold">
                      {dataKas?.kas?.name}
                    </h1>
                    <p className="text-3xl font-bold">
                      {rupiah(parseInt(dataKas?.kas?.amount))}
                    </p>
                    <p className="text-sm font-bold pt-1">
                      {rupiah(dataKas?.kas?.price_per_member)} / member
                    </p>
                  </div>
                )}
                <div>
                  <img src={kasWallet} alt="kas wallet images" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* card kas data end */}
        {/* card kas payment */}
        <div>
          {dataKas?.kas?.is_primary ? (
            <div>
              <div className="flex flex-col justify-center items-center py-4 pb-2">
                <img
                  src={memberDetailKasUtama}
                  alt="member detail kas utama image"
                />
                <p className="text-xl font-bold text-center py-3">
                  Yuk Bayar Kas Rutin
                </p>
              </div>
            </div>
          ) : (
            <div>
              {/* Card list status anggota */}
              <div className="px-4 pt-4 pb-10">
                <div className="flex justify-between items-center mb-4">
                  <h1 className="text-2xl font-semibold">Status Bayar</h1>
                </div>
                {isPendingMembersKasStatus ? (
                  <>
                    <PulseLoadingPost size={16} />
                    <PulseLoadingPost size={16} mt={3} />
                    <PulseLoadingPost size={16} mt={3} />
                    <PulseLoadingPost size={16} mt={3} />
                  </>
                ) : (
                  <>
                    {dataMembersKasStatus?.data.map((member, i) => {
                      const isPaid = member.payment_status !== NOT_PAID;
                      const isYou = user._id === member.user._id;
                      return (
                        <div
                          key={i}
                          className={`flex my-3 ${
                            isPaid ? "bg-white" : "bg-gray-100"
                          } justify-between items-center shadow-sm rounded-md p-2`}
                        >
                          <div className="flex justify-start items-center ">
                            <div>
                              {/* profile picture */}
                              <div className="w-14 h-14 bg-gray-200 rounded-full flex items-center justify-center overflow-hidden">
                                <img
                                  src={member.user.user_photo.image_url}
                                  alt={member.user.nama_lengkap}
                                   
                                />
                              </div>
                            </div>
                            <div className="flex justify-center items-center h-full flex-col mx-3">
                              {/* profil name */}
                              <div className="text-lg">
                                <p>{member.user.nama_lengkap}</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            {/* bill status */}
                            <div className="flex justify-center items-center">
                              {isPaid ? (
                                <div className="text-xs flex bg-gray-100 rounded-md p-2">
                                  <div className="bg-primary-500 text-xs font-light rounded-full mx-1">
                                    <IconCheck />
                                  </div>
                                  <span>
                                    {isYou ? "Kamu sudah bayar" : "Sudah bayar"}
                                  </span>
                                </div>
                              ) : (
                                <div className="text-xs font-semibold ">
                                  <span>
                                    {isYou ? "Kamu belum bayar" : "Belum bayar"}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          )}
          {isPendingMembersKasStatus ? (
            <div className="m-2 mx-4">
              <PulseLoadingPost size={16} />
            </div>
          ) : !(dataKas?.kas?.is_primary) && isMemberPaid() ? (
            <div className="m-2 mx-4 rounded-xl bg-gray-400 text-white overflow-hidden cursor-not-allowed">
              <div className="flex justify-center items-center p-4">
                <p>Kamu sudah bayar</p>
              </div>
            </div>
          ) : (
            <div className="m-2 mx-4 rounded-xl overflow-hidden">
              <LoadingButton
                isPending={isPendingPayKas}
                onClick={() => performPayKas()}
              >
                <div className="h-7 flex justify-center items-center">
                  <p>Bayar Kas</p>
                </div>
              </LoadingButton>
            </div>
          )}
        </div>
      </div>
      <div className="px-4 pt-4 pb-10"></div>
      <Navigation />
    </ScreenContainer>
  );
};

export default DetailKasMemberPOV;
